<template>
  <v-container grid-list-md>
    <v-layout row wrap>
      <v-flex xs12 md6 my-auto>
        <h1>Mantenimiento</h1>
      </v-flex>
    </v-layout>

    <v-layout row wrap mt-2>
      <v-flex xs12 md6>
        <v-card class="ma-1" elevation="1">
          <v-card-title>
            Borrado de notificaciones
          </v-card-title>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Días de antiguedad"
                  v-model="days"
                  filled
                  hide-details="auto"
                  prepend-icon="mdi-calendar"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Canal"
                  v-model="channel"
                  filled
                  hide-details="auto"
                  prepend-icon="mdi-access-point"
                ></v-text-field>
              </v-col>
            </v-row>
         
          </v-container>

          <v-card-actions class="text-center">
            
            <v-flex>
              <v-btn color="accent" @click="deleteNotifications">Eliminar notificaciones</v-btn>
            </v-flex>     

            <v-card-subtitle style="max-width: 220px;">
              Sólo notificaciones de más de 15 días de antigüedad
            </v-card-subtitle>   
          </v-card-actions>

        </v-card>
      </v-flex>

    </v-layout>
  </v-container>
</template>
<script>
export default {
  components: {},
  name: "Configuration",
  data: () => ({  
    days: 30,  
    channel: null,
  }),

  created() {
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    user() {
      return this.$store.getters.getUser;
    },   
  },
  methods: {
    
    deleteNotifications() {

      if (!this.days || (this.days && this.days < 15))
        this.days = 30;

      this.$store.dispatch("deleteNotifications", {days: this.days, channel: this.channel});
    },
    
  },
};
</script>

<style scoped>
.v-card__title {
  padding-bottom: -4px !important;
  margin-bottom: -30px !important;
}
</style>
